.react-paginate-container{
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
    padding: 0;
}
.react-paginate-container li a {
    width: 40px;
    height: 40px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5e6e82 !important;

}
.react-paginate-container li.active a {
    background: #5541D7;
    color: #fff!important;
}
.react-paginate-container li.disabled a {
    opacity: 0.4;
}
.react-paginate-container li a:hover {
    background: #5541D7;
    color: #fff!important;
}
.react-paginate-container li.disabled a:hover {
    color: #5e6e82 !important;background: #fff;cursor: default;
}