.custom_edge .remove_edge{
    display: none;
    background: #fff;
    position: absolute;
    cursor: pointer;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
}
.custom_edge:hover .remove_edge{
    display: block;
}
.remove_edge:hover{
    color: #000;
}